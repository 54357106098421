import React from "react";
import Box from "@material-ui/core/Box";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

function PdfIconMenu({ color, background, width = 25, height = 25 }) {
  return (
    <Box
      style={{
        padding: "5px",
        backgroundColor: background,
        display: "flex",
        borderRadius: "4px",
      }}
    >
      <PictureAsPdfIcon
        style={{ color: color, width: width, height: height }}
      />
    </Box>
  );
}

export default PdfIconMenu;
