import React from "react";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";

function EditIconMenu({ color, background, width = 25, height = 25 }) {
  return (
    <Box
      style={{
        padding: "5px",
        backgroundColor: background,
        display: "flex",
        borderRadius: "4px",
      }}
    >
      <EditIcon style={{ color: color, width: width, height: height }} />
    </Box>
  );
}

export default EditIconMenu;
