import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import {
  LayersIcon,
  TrashIcon,
} from "components/common/LunaLens/images/images";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import "@szhsin/react-menu/dist/index.css";

const CardOptionsItems = [
  {
    id: -1,
    label: "Change Category",
    value: "CHANGE_CATEGORY",
    Icon: LayersIcon,
  },
  {
    id: -3,
    label: "Delete",
    value: "DELETE_CATEGORY",
    Icon: TrashIcon,
  },
];

const CardOptions = ({
  id,
  categoryId,
  onSelectedAnalysisCategory,
  onActionSelected,
}) => {
  const classes = useStyles();

  const categoryList = useLunaLensStore((state) => state.categoryList);

  const handleClick = (event) => {
    onSelectedAnalysisCategory(id);
  };

  const handleMenuItemClick = (e, data) => {
    onActionSelected(data);
  };

  const menuItemClassName = ({ hover }) => (hover ? classes.test : {});

  return (
    <React.Fragment key={id}>
      <Menu
        menuButton={
          <Button
            className={classes.buttonVertical}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertOutlinedIcon
              className={classes.moreIcon}
              fontSize="medium"
            />
          </Button>
        }
      >
        {CardOptionsItems.map((item) => {
          return item.id === -1 ? (
            <SubMenu
              key={item.id}
              label={({ hover }) => (
                <>
                  <LayersIcon
                    color={hover ? "#4F98BC" : "#747474"}
                    background={hover ? "#EDF5F8" : "#ECECEC"}
                  />
                  <Typography
                    style={{
                      color: hover ? "#4F98BC" : "#747474",
                      marginLeft: "8px",
                    }}
                  >
                    {item.label}
                  </Typography>
                </>
              )}
            >
              {categoryList
                .filter((subItem) => +subItem.id !== categoryId)
                .map((subItem) => {
                  return (
                    <MenuItem
                      key={subItem.id}
                      data={subItem.value}
                      onClick={(e) =>
                        handleMenuItemClick(e, {
                          value: subItem.value,
                          id: subItem.id,
                        })
                      }
                    >
                      {({ hover }) => (
                        <Typography
                          style={{
                            color: hover ? "#4F98BC" : "#747474",
                            marginLeft: "8px",
                          }}
                        >
                          {subItem.label}
                        </Typography>
                      )}
                    </MenuItem>
                  );
                })}
            </SubMenu>
          ) : (
            <MenuItem
              key={item.id}
              data={item.value}
              className={menuItemClassName}
              onClick={(e) => handleMenuItemClick(e, { value: item.value })}
            >
              {({ hover }) => (
                <>
                  <item.Icon
                    color={hover ? "#4F98BC" : "#747474"}
                    background={hover ? "#EDF5F8" : "#ECECEC"}
                  />
                  <Typography
                    style={{
                      color: hover ? "#4F98BC" : "#747474",
                      marginLeft: "8px",
                    }}
                  >
                    {item.label}
                  </Typography>
                </>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default CardOptions;
