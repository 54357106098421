import React from "react";
import Box from "@material-ui/core/Box";
import { ChatIconOutline } from "components/common/LunaLens/images/images";

function CommentIcon({ color, background, width = 26, height = 26 }) {
  return (
    <Box
      style={{
        padding: "5px",
        backgroundColor: background,
        display: "flex",
        borderRadius: "4px",
      }}
    >
      <ChatIconOutline color={color} width={width} height={height} />
    </Box>
  );
}

export default CommentIcon;
