import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import {
  DocumentTextIcon,
  InspectionIcon,
  ListAnalysisIcon,
  MapPinIcon,
} from "./images/images";
import AnalysisLunaChat from "./analysisLunaChat";

const AnalysisInformationCard = ({
  analysisTitle,
  location,
  summary,
  description,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardWTContainer}>
      <Box className={classes.chatLensIconContainer}>
        <AnalysisLunaChat />
      </Box>
      <Box className={classes.cardWTContainerHeader}>
        <Typography variant="body1" className={classes.cardWTTitle}>
          {analysisTitle}
        </Typography>
        <Box className={classes.cardWTContainerInspection}>
          <InspectionIcon />
          <Typography variant="body1" className={classes.cardWTInspectionText}>
            Inspection
          </Typography>
        </Box>
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <MapPinIcon />
          <Typography
            component="span"
            variant="body1"
            className={classes.cardWTTitle}
          >
            Location:
          </Typography>
        </Box>
        <Typography variant="body1">{location}</Typography>
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <ListAnalysisIcon />
          <Typography
            component="span"
            variant="body1"
            className={classes.cardWTTitle}
          >
            WT Summary:
          </Typography>
        </Box>
        <Typography variant="body1">{summary}</Typography>
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <DocumentTextIcon />
          <Typography
            component="span"
            variant="body1"
            className={classes.cardWTTitle}
          >
            WT Description:
          </Typography>
        </Box>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
};

export default AnalysisInformationCard;
