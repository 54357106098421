import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import useLunaLensStore from "store/lunaLensAnalysis";
import AnalysisCardHistory from "./analysisCardHistory";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import FormInput from "components/ui/FormContent/formInput";
import DialogUpdateAnalysis from "./dialog/dialogUpdateAnalysis";

import useStyles from "./styles";
import { getAnalysisReport } from "services/lunaLensService";

const EditAnalysisTab = ({ id }) => {
  const classes = useStyles();
  const [openSaveAnalysisDialog, setOpenSaveAnalysisDialog] = useState(false);
  const [value, setValue] = useState("");
  const [analysisCardSelected, setAnalysisCardSelected] = useState({});
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);

  const setAnalysisHistory = useLunaLensStore(
    (state) => state.setAnalysisHistory
  );

  useEffect(() => {
    const getReport = async () => {
      setLoadingAnalysis(true);
      const analysisData = await getAnalysisReport({ id: id });
      setAnalysisHistory(
        analysisData.data.analysis.luna_lens_workticket_analysis[0]
      );
      setAnalysisCardSelected({
        analysisId: analysisData.data.analysis.id,
        responseText: analysisData.data.analysis.response_text,
      });
      setLoadingAnalysis(false);
    };
    getReport();
  }, [setAnalysisHistory, id]);

  return (
    <Box className={classes.BodyDialogContainer}>
      <Box className={classes.historyContainer}>
        {loadingAnalysis ? (
          <Skeleton
            animation="wave"
            variant="rect"
            height={700}
            style={{ marginBottom: "24px" }}
          />
        ) : (
          <>
            <Box className={classes.historyDetailContainer}>
              <AnalysisCardHistory analysis={analysisCardSelected} />
            </Box>
            <Box className={classes.historyBottomContainer}>
              <Box className={classes.historyButtonContainer}>
                <Button
                  className={classes.buttonAnalysisFilled}
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => setOpenSaveAnalysisDialog(true)}
                >
                  Save Changes
                </Button>
              </Box>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="prompt"
                placeholder="Add something here to deepen insights..."
                styleOverride={{ backgroundColor: "#F8F8F8" }}
                endAdornment={
                  value.length > 0 && (
                    <IconButton
                      aria-label="close"
                      onClick={() => {}}
                      className={classes.sendButton}
                    >
                      <SendIcon className={classes.sendIcon} />
                    </IconButton>
                  )
                }
                value={value}
                onChange={(e) => setValue(e.target.value)}
                readonly={true}
              />
            </Box>
          </>
        )}
      </Box>
      <DialogUpdateAnalysis
        open={openSaveAnalysisDialog}
        handleCancel={() => {
          setOpenSaveAnalysisDialog(false);
        }}
        closeLensDialog={() => {}}
        indexAnalysis={id}
        analysis={{ analysisId: id }}
      />
    </Box>
  );
};

export default EditAnalysisTab;
