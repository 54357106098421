import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import BadgeIcon from "./badgeIcon";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ImageGallery from "./gallery/imageGallery";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import useLunaLensStore from "store/lunaLensAnalysis";
import ZeroStateGallery from "./zeroStateGallery";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
    position: "unset",
    boxShadow: "none",
    margin: "16px 0",
    "&:before": {
      display: "none",
      border: "none",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
  },
  expandIcon: {
    marginTop: 6,
    marginBottom: 0,
    alignItems: "center",
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&$expanded": {
      margin: "auto",
    },
  },
  defaultBorder: {
    border: "1px solid #ECECEC",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expanded: {
    "& .collapsIconWrapper": {
      display: "none",
      transform: "none",
      marginTop: "20",
    },
    "& .expandIconWrapper": {
      display: "block",
    },
  },
  contracted: {
    "& .collapsIconWrapper": {
      display: "block",
    },
    "& .expandIconWrapper": {
      display: "none",
      transform: "none",
    },
  },
  hideBorder: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
    },
  },
  buttonMediaFilled: {
    color: "white",
    textTransform: "none",
    backgroundColor: "#4F98BC",
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "6px 34px 6px 34px",
    minWidth: "0",
    margin: "8px 0px",
    alignSelf: "flex-end",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#4488A3",
    },
  },
  buttonSelectAll: {
    color: "#4F98BC",
    textTransform: "none",
    border: "1px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 16px 0px 16px",
    minWidth: "0",
    backgroundColor: "#EDF5F8",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
  categoryContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "20px",
  },
  categoryDescription: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  categoryDetails: {
    margin: "0px 16px 16px 16px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
  },
  categoryGalleryWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    overflow: "hidden",
  },
  categoryGalleryWrapperVerification: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    overflow: "hidden",
    minHeight: "350px",
  },
  galleryHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "12px 0px 16px 0px",
  },
  galleryHeaderOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
}));

const CustomExpandIcon = ({ expanded }) => {
  const classes = useStyles();
  return (
    <Box className={expanded ? classes.expanded : classes.contracted}>
      <Box className="expandIconWrapper">
        <ExpandLessIcon style={{ margin: "0px" }} />
      </Box>
      <Box className="collapsIconWrapper">
        <ChevronRightIcon />
      </Box>
    </Box>
  );
};

const AnalysisCategoriesAccordion = ({ newAnalysis, workticketId }) => {
  const classes = useStyles();
  const refs = useRef([]);
  const [expanded, setExpanded] = useState(false);
  const [activeSelectAll, setActiveSelectAll] = useState({
    quality_issues: false,
    general_observations: false,
    health_and_safety_risks: false,
    services_recommendations: false,
    asset_tagging: false,
    sla_compliance: false,
    service_verification: false,
  });
  const categoryList = useLunaLensStore((state) => state.categoryList);
  const media = useLunaLensStore((state) => state.media);
  const resetCategoriesByCategory = useLunaLensStore(
    (state) => state.resetCategoriesByCategory
  );
  const setAllSelectedMedia = useLunaLensStore(
    (state) => state.setAllSelectedMedia
  );
  const setCategorySelected = useLunaLensStore(
    (state) => state.setCategorySelected
  );
  const resetSelectedMediaByCategory = useLunaLensStore(
    (state) => state.resetSelectedMediaByCategory
  );
  const globalSelectedMediaCount = useLunaLensStore(
    (state) => state.globalSelectedMediaCount
  );
  const setGlobalSelectedMediaCount = useLunaLensStore(
    (state) => state.setGlobalSelectedMediaCount
  );

  useEffect(() => {
    if (expanded !== false && refs.current[expanded]) {
      refs.current[expanded].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectAll = (category) => {
    resetSelectedMediaByCategory(category);
    resetCategoriesByCategory(category);
    setGlobalSelectedMediaCount(category, 0);
    if (!activeSelectAll[category]) {
      const mediaIndexes = media
        .slice(0, 25 - globalSelectedMediaCount[category])
        .map((_, index) => index);
      setAllSelectedMedia(mediaIndexes, category);
      setCategorySelected(category, mediaIndexes.length);
      setGlobalSelectedMediaCount(
        category,
        globalSelectedMediaCount[category] + mediaIndexes.length
      );
    } else {
      setCategorySelected(category, 0);
    }
    setActiveSelectAll((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <Typography variant="h5">Select Categories and Media</Typography>
        {Object.values(globalSelectedMediaCount).reduce(
          (acc, count) => acc + count,
          0
        ) > 0 && (
          <Typography variant="h5" style={{ color: "#4F98BC" }}>
            {`${Object.values(globalSelectedMediaCount).reduce(
              (acc, count) => acc + count,
              0
            )}/175 Files`}
          </Typography>
        )}
      </Box>
      <Box className={classes.root}>
        {categoryList.map((category) => {
          return (
            <Accordion
              key={category.id}
              elevation={0}
              expanded={expanded === category.id}
              onChange={handleChange(category.id)}
              ref={(el) => (refs.current[category.id] = el)}
              TransitionProps={{ unmountOnExit: true }}
              style={{
                backgroundColor: newAnalysis ? "white" : "#F8F8F8",
                border: newAnalysis
                  ? category.selected
                    ? "1px solid #4F98BC"
                    : "1px solid #ECECEC"
                  : "0px solid #ECECEC",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <CustomExpandIcon
                    expanded={expanded === category.id}
                    sx={{ margin: "20 28px" }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box className={classes.categoryContainer}>
                  <Box className={classes.categoryDescription}>
                    <BadgeIcon
                      iconType={category.icon}
                      color={category.icon_color}
                      backgroundColor={category.background_color}
                      background={category.background_color}
                    />
                    <Typography
                      variant="body1"
                      className={classes.categoryText}
                    >
                      {category.label}
                    </Typography>
                  </Box>
                  {newAnalysis &&
                    expanded !== category.id &&
                    category?.files > 0 && (
                      <Typography variant="body2">
                        {`${category.files} Files`}
                      </Typography>
                    )}
                  {!newAnalysis && (
                    <Typography variant="body2">
                      {`${category.files} Files`}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.categoryDetails}>
                {category.value !== "service_verification" ? (
                  <Box className={classes.categoryGalleryWrapper}>
                    {media.length > 0 ? (
                      <>
                        {newAnalysis && (
                          <Box className={classes.galleryHeader}>
                            <Typography variant="body1">
                              Select up to 25 Media Elements
                            </Typography>
                            <Box className={classes.galleryHeaderOptions}>
                              <Button
                                className={classes.buttonSelectAll}
                                onClick={() => {
                                  handleSelectAll(category.value);
                                }}
                              >
                                {!activeSelectAll[category.value]
                                  ? "Select All"
                                  : "Deselect All"}
                              </Button>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#4D4D4D",
                                }}
                              >
                                {`${category.files ?? 0}/25 Files`}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <ImageGallery
                          imageUrls={media}
                          numberImages={28}
                          viewOnly={false}
                          category={category.value}
                        />
                      </>
                    ) : (
                      <ZeroStateGallery />
                    )}
                  </Box>
                ) : (
                  <Box className={classes.categoryGalleryWrapperVerification}>
                    {media.length > 0 ? (
                      <>
                        {newAnalysis && (
                          <Box className={classes.galleryHeader}>
                            <Typography variant="body1">
                              Select up to 25 Media Elements
                            </Typography>
                            <Box className={classes.galleryHeaderOptions}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#4D4D4D",
                                }}
                              >
                                {`${category.files ?? 0}/25 Files`}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "16px" }}
                        >
                          BEFORE
                        </Typography>
                        <ImageGallery
                          imageUrls={media}
                          numberImages={28}
                          viewOnly={false}
                          category={category.value}
                          verification="before"
                        />
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "16px", marginTop: "16px" }}
                        >
                          AFTER
                        </Typography>
                        <ImageGallery
                          imageUrls={media}
                          numberImages={28}
                          viewOnly={false}
                          category={category.value}
                          verification="after"
                        />
                      </>
                    ) : (
                      <ZeroStateGallery />
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default AnalysisCategoriesAccordion;
