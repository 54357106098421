import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core/";
import BadgeIcon from "./badgeIcon";

import useStyles from "./styles";

const CategoryTag = ({
  iconType = "documentPlus",
  color = "#000",
  backgroundColor = "#999",
  width = 18,
  height = 18,
  background = true,
  text = "",
  deleted = false,
  hideLabel = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      className={classes.categoryContainerTag}
      style={
        background
          ? {
              backgroundColor: backgroundColor,
              border: `2px solid ${color}`,
            }
          : {}
      }
    >
      <BadgeIcon
        iconType={iconType}
        color={color}
        backgroundColor={backgroundColor}
        background={true}
        width={width}
        height={height}
      />
      {!hideLabel && (
        <Typography
          variant="body1"
          style={deleted ? { textDecoration: "line-through" } : {}}
          className={`${classes.categoryTextLabel} ${
            isSmallDevice ? classes.hideOnSmallDevices : ""
          }`}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default CategoryTag;
