import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageItem from "./imageGalleryGridItem";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(5, 1fr)",
      gap: 1,
    },
  },
}));

const ImageGalleryGrid = ({
  pageIndex,
  images,
  imagesPerPage,
  selectedImages,
  handleImageClick,
  viewOnly,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.gridContainer}>
      {images.map((image, index) => {
        const globalIndex = pageIndex * imagesPerPage + index;
        const isSelected = selectedImages?.includes(globalIndex);
        return (
          <ImageItem
            key={index}
            id={image.id}
            url={image.url}
            index={globalIndex}
            isSelected={isSelected}
            onClick={handleImageClick}
            viewOnly={viewOnly}
            mime_type={image.mime_type}
          />
        );
      })}
    </Box>
  );
};

export default ImageGalleryGrid;
