import React from "react";
import PageHeader from "components/common/PageHeader/pageHeader";
import { withRouter, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import EditAnalysisTab from "../editAnalysisTab";
import NewAnalysisTab from "../newAnalysisTab";

const LensAnalysisPage = (props) => {
  const { wtId, wtNumber, id, reportType } = props.match.params;

  return (
    <>
      <PageHeader pageTitle="Luna Lens Analysis" lens={true}>
        <Link to={`/workticket/${wtId}`}>{wtNumber}</Link>
      </PageHeader>
      <Box style={{ overflowY: "auto" }}>
        {reportType === "new" ? (
          <NewAnalysisTab id={id} />
        ) : (
          <EditAnalysisTab id={id} />
        )}
      </Box>
    </>
  );
};

export default withRouter(LensAnalysisPage);
