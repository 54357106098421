import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import { noPhotos } from "components/common/LunaLens/images/images";

const ZeroStateGallery = () => {
  const classes = useStyles();
  return (
    <Box className={classes.zeroStateContainer}>
      <Box className={classes.zeroBodyContainer}>
        <Box className={classes.zeroStatePhotosCircle}>{noPhotos(48, 48)}</Box>
        <Typography
          variant="h4"
          align="center"
          className={classes.zeroStateTitle}
        >
          No Files Uploaded Yet
        </Typography>
        <Typography align="center">
          Do something awesome like updating the first file to this workticket.
        </Typography>
      </Box>
    </Box>
  );
};

export default ZeroStateGallery;
