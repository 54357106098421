import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
} from "components/common/LunaLens/images/images";
import useStyles from "./styles";

const iconsMap = {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
};

const CategorySelectionTag = ({ category }) => {
  const classes = useStyles();
  const renderIcon = iconsMap[category?.icon]
    ? iconsMap[category?.icon](20, 20, category?.icon_color)
    : null;

  return (
    <Box
      className={classes.categorySelectionContainer}
      style={{
        backgroundColor: category?.background_color,
        border: `1px solid ${category?.icon_color}`,
      }}
    >
      {renderIcon}
      <Typography variant="body2">{category?.label}</Typography>
    </Box>
  );
};

export default CategorySelectionTag;
