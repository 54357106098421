import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIconMenu from "./images/editIcon";
import CommentIcon from "./images/commentIcon";
import Typography from "@material-ui/core/Typography";
import { TrashIcon } from "components/common/LunaLens/images/images";

import useStyles from "./styles";
import ViewIconMenu from "./images/viewIcon";
import PdfIconMenu from "./images/pdfIcon";

const HistoryOptionsItems = [
  {
    id: 1,
    label: "View Report",
    value: "VIEW_REPORT",
    Icon: ViewIconMenu,
  },
  {
    id: 2,
    label: "Edit Report",
    value: "EDIT_REPORT",
    Icon: EditIconMenu,
  },
  {
    id: 3,
    label: "Add Comment",
    value: "ADD_COMMENT",
    Icon: CommentIcon,
  },
  {
    id: 4,
    label: "Print PDF",
    value: "PRINT_PDF",
    Icon: PdfIconMenu,
  },
  {
    id: 5,
    label: "Delete",
    value: "DELETE_ANALYSIS",
    Icon: TrashIcon,
  },
];

const HistoryOptions = ({ onOptionClick, onCardClick, data, status }) => {
  const classes = useStyles();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);

  const handleClick = (event) => {
    onCardClick(data);
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleMenuItemClick = (e) => {
    setMenuPosition(null);
    setHoveredItem(null);
    const value = e.currentTarget.getAttribute("data");
    onOptionClick(value);
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  return (
    <>
      <Button
        className={classes.buttonHorizontal}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon className={classes.moreIcon} fontSize="medium" />
      </Button>
      <Menu
        id="simple-menu"
        open={!!menuPosition}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 250,
          },
        }}
      >
        {HistoryOptionsItems?.filter((item) => {
          if (status === 0 || status === 2) {
            return item.value !== "EDIT_REPORT";
          } else if (status === 1) {
            return item.value !== "VIEW_REPORT";
          }
          return item;
        }).map((item) => {
          return (
            <MenuItem
              key={item.id}
              data={item.value}
              onMouseEnter={() => setHoveredItem(item.id)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={(e) => handleMenuItemClick(e)}
            >
              <item.Icon
                color={hoveredItem === item.id ? "#4F98BC" : "#747474"}
                background={hoveredItem === item.id ? "#EDF5F8" : "#ECECEC"}
              />
              <Typography
                style={{
                  color: hoveredItem === item.id ? "#4F98BC" : "#747474",
                  marginLeft: "8px",
                }}
              >
                {item.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default HistoryOptions;
