import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

import useStyles from "./styles";

const DialogLoadingLuna = ({ open, handleCancel }) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
    >
      <Box className={classes.loadingContainer}>
        <LoadingStateHorizontal isVisible={open} />
      </Box>
    </Dialog>
  );
};

export default DialogLoadingLuna;
