import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import FormInput from "components/ui/FormContent/formInput";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

import useStyles from "./styles";
import AnalysisCardHistory from "./analysisCardHistory";
import DialogUpdateAnalysis from "./dialog/dialogUpdateAnalysis";

const HistoryAnalysisDetail = ({
  categories,
  indexAnalysis,
  closeLensDialog,
  analysis,
}) => {
  const classes = useStyles();
  const [openSaveAnalysisDialog, setOpenSaveAnalysisDialog] = useState(false);
  const [value, setValue] = useState("");

  return (
    <>
      <Box className={classes.historyContainer}>
        <Box className={classes.historyDetailContainer}>
          <AnalysisCardHistory analysis={analysis} />
        </Box>
        <Box className={classes.historyBottomContainer}>
          <Box className={classes.historyButtonContainer}>
            <Button
              className={classes.buttonAnalysisFilled}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => setOpenSaveAnalysisDialog(true)}
            >
              Save Changes
            </Button>
          </Box>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="prompt"
            placeholder="Add something here to deepen insights..."
            styleOverride={{ backgroundColor: "#F8F8F8" }}
            endAdornment={
              value.length > 0 && (
                <IconButton
                  aria-label="close"
                  onClick={() => {}}
                  className={classes.sendButton}
                >
                  <SendIcon className={classes.sendIcon} />
                </IconButton>
              )
            }
            value={value}
            onChange={(e) => setValue(e.target.value)}
            readonly={true}
          />
        </Box>
      </Box>
      <DialogUpdateAnalysis
        open={openSaveAnalysisDialog}
        handleCancel={() => {
          setOpenSaveAnalysisDialog(false);
        }}
        closeLensDialog={closeLensDialog}
        indexAnalysis={indexAnalysis}
        analysis={analysis}
      />
    </>
  );
};

export default HistoryAnalysisDetail;
